import React, { useState } from "react";

import {
  Button,
  Image,
  Placeholder,
  Transition,
  Dimmer,
  Header,
} from "semantic-ui-react";

const Project = ({ loadingProjects, img, data, index, handler }) => {
  const [show, setShow] = useState(false);

  return (
    <Dimmer.Dimmable
      style={{
        borderRadius: "5px",
        minHeight: "200px",
        minWidth: "100%",
        maxWidth: "450px",
      }}
      dimmed={show}
      blurring
      onClick={() => setShow(true)}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <Placeholder
        style={{ display: loadingProjects ? "block" : "none" }}
        fluid
      >
        <Placeholder.Image square />
      </Placeholder>

      <Transition
        visible={!loadingProjects}
        animation="fade down"
        duration={index * 100 + 500}
      >
        <Image
          style={{
            display: loadingProjects ? "none" : "block",
            borderRadius: "5px",
            width: "100%",
            objectFit: "cover",
          }}
          src={img}
        />
      </Transition>

      <Dimmer
        style={{ borderRadius: "5px" }}
        active={show}
        onClickOutside={() => setShow(false)}
      >
        <Header style={{ marginBottom: "30px", color: "white" }} as="h1">
          {data.title}
        </Header>
        <Button
          color="teal"
          inverted
          onClick={(evt) =>
            handler({
              ...data,
              img,
            })
          }
        >
          More Info
        </Button>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default Project;
