import React, { useState, useEffect, useRef } from "react";

import {
  Transition,
  Dimmer,
  Header,
  Icon,
  Segment,
  Loader,
  Card,
} from "semantic-ui-react";

import Navbar from "../components/Navbar";
import Project from "../components/Project";
import Details from "../components/Details";

const projects = [
  {
    title: "GeoPins",
    description:
      "Users can pin different locations on the map and share their own content with other users in real time. You'll share pictures and reviews of the location and area, interact with other users by adding comments to your pins, and manage the pins you've created",
    tech: [
      "JavaScript",
      "Node JS",
      "Express JS",
      "MongoDB",
      "GraphQL",
      "Apollo",
      "ReactJS",
      "Material UI",
    ],
    features: [
      "Authentication",
      "OAuth 2.0 Authorization",
      "GraphQL Server/Client",
      "Dynamic Image Upload",
      "Global State within React Hooks",
      "Real-time Geolocalization",
      "GraphQL Subscriptios",
      "Optimistic UI",
    ],
    img: process.env.PUBLIC_URL + "/images/projects/geopins.jpg",
    repo: "https://github.com/jjdevco/GeoPins_MERN_App",
    live: "https://elegant-easley-272829.netlify.app/",
  },
  {
    title: "InstaClone API",
    description: "Efficient and secure RESTful API",
    tech: ["JavaScript", "Node JS", "Express JS", "MongoDB", "Redis", "JWT"],
    features: [
      "Authentication",
      "JWT Authorization",
      "API Rest",
      "Following Feature",
      "Queries Rate Limit",
      "Cached Queries",
      "Custom Error Handler",
      "Custom Validation Handler",
    ],
    img: process.env.PUBLIC_URL + "/images/projects/node.jpg",
    repo: "https://github.com/jjdevco/InstaClone_API",
    live: "https://instaclone-api-jjdevco.herokuapp.com/",
  },
  {
    title: "Social App",
    description:
      "Full stack MERN App like Twitter, made with Firebase cloud funtions and Express.js as Backend.",
    tech: [
      "Html",
      "CSS",
      "Material UI",
      "JavaScript",
      "React JS",
      "Node JS",
      "Firebase Firestore",
      "Firebase Storage",
    ],
    features: [
      "Authentication",
      "JWT Authorization",
      "API Rest",
      "Cloud Functions",
      "Styled Components",
    ],
    img: process.env.PUBLIC_URL + "/images/projects/1.jpg",
    repo: "https://github.com/jjdevco/mern-app",
    live: "https://socialapp-94844.web.app/",
  },

  {
    title: "Pomodoro Clock",
    description:
      "Pomodoro Clock will help you do more in less time and regain your lost concentration.",
    tech: ["Html", "CSS", "JavaScript", "React JS"],
    img: process.env.PUBLIC_URL + "/images/projects/2.jpg",
    repo: "https://codepen.io/jjdevco/pen/yLyWKyW",
    live: "https://codepen.io/jjdevco/full/yLyWKyW",
  },

  {
    title: "Drum Machine",
    description:
      "Create music with the Drum machine app in a few clicks by your own. Be a beat maker with super pads on the launchpad.",
    tech: ["Html", "CSS", "JavaScript", "React JS"],
    img: process.env.PUBLIC_URL + "/images/projects/3.jpg",
    repo: "https://codepen.io/jjdevco/pen/jOPNvmo",
    live: "https://codepen.io/jjdevco/full/jOPNvmo",
  },

  {
    title: "Random Quote",
    description:
      "Random quote is a collection of awesome quotes with different categories. It contains sayings from philosophers, poets, writers, spiritual thinkers and other great minds.",
    tech: ["Html", "CSS", "JavaScript", "React JS"],
    img: process.env.PUBLIC_URL + "/images/projects/4.jpg",
    repo: "https://codepen.io/jjdevco/pen/abzMKWv",
    live: "https://codepen.io/jjdevco/full/abzMKWv",
  },

  {
    title: "Choropleth Map",
    description:
      "Choropleth Map display divided geographical areas or regions that are coloured, shaded or patterned in relation to a data variable. This provides a way to visualise values over a geographical area, which can show variation or patterns across the displayed location. ",
    tech: ["Html", "CSS", "JavaScript", "D3 JS"],
    img: process.env.PUBLIC_URL + "/images/projects/5.jpg",
    repo: "https://codepen.io/jjdevco/pen/xxGxZmd",
    live: "https://codepen.io/jjdevco/full/xxGxZmd",
  },

  {
    title: "Heat Map",
    description:
      "Heatmaps visualise data through variations in colouring. When applied to a tabular format, Heatmaps are useful for cross-examining multivariate data, through placing variables in the rows and columns and colouring the cells within the table.",
    tech: ["Html", "CSS", "JavaScript", "D3 JS"],
    img: process.env.PUBLIC_URL + "/images/projects/6.jpg",
    repo: "https://codepen.io/jjdevco/pen/jOPNvmo",
    live: "https://codepen.io/jjdevco/full/jOPNvmo",
  },

  {
    title: "MD Previewer",
    description:
      "MD Previewr is a markdown editor app that (surprise) allows you to quickly and easily edit markdown files, and then share them as you please.",
    tech: ["Html", "CSS", "JavaScript", "React JS"],
    img: process.env.PUBLIC_URL + "/images/projects/7.jpg",
    repo: "https://codepen.io/jjdevco/pen/BaybOJp",
    live: "https://codepen.io/jjdevco/full/BaybOJp",
  },

  {
    title: "Calculator",
    description:
      "Calculator provides simple mathematical functions in a beautifully designed app to perform basic calculations such as addition, subtraction, multiplication, and division.",
    tech: ["Html", "CSS", "JavaScript", "React JS"],
    img: process.env.PUBLIC_URL + "/images/projects/8.jpg",
    repo: "https://codepen.io/jjdevco/pen/xxbeeBB",
    live: "https://codepen.io/jjdevco/full/xxbeeBB",
  },
];

const styles = {
  root: {
    height: "100vh",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  img: {
    width: "100%",
    height: "100%",
    maxHeight: "450px",
    objectFit: "cover",
  },

  dimmerContent: {
    height: "450px",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
};

const Projects = () => {
  const [loading, setLoading] = useState(true);
  const [loadingProjects, setLoadingProjects] = useState(true);
  const [cards, setCards] = useState([]);
  const counter = useRef(0);

  const [open, setOpen] = useState(false);

  const [modalData, setModalData] = useState({
    img: "",
    title: "",
    description: "",
    tech: [],
    features: [],
    repo: "",
    live: "",
  });

  const handleModalOpen = ({
    img,
    title,
    description,
    tech,
    features,
    repo,
    live,
  }) => {
    setModalData({
      img,
      title,
      description,
      tech,
      features,
      repo,
      live,
    });
    setOpen(true);
  };

  const imageLoaded = () => {
    counter.current += 1;
    if (counter.current >= projects.length) {
      setLoadingProjects(false);
    }
  };

  useEffect(() => {
    let imgsFetched = [];

    setTimeout(() => {
      projects.forEach((project, index) => {
        fetch(project.img)
          .then((response) => response.blob())
          .then((images) => {
            imgsFetched.push({ url: URL.createObjectURL(images), item: index });
            setCards([...imgsFetched]);
            imageLoaded();
          });
      });
    }, 300);
  }, []);

  return (
    <>
      <Navbar />

      <Segment style={{ ...styles.root, display: loading ? "block" : "none" }}>
        <Dimmer active>
          <Loader color="teal" size="massive">
            Loading
          </Loader>
        </Dimmer>
      </Segment>

      <div style={{ display: loading ? "none" : "block" }}>
        <Dimmer.Dimmable
          style={{
            height: "450px",
            margin: 0,
            border: 0,
            padding: 0,
            borderRadius: 0,
            boxShadow: "none",
          }}
          as={Segment}
          dimmed={true}
          blurring
        >
          <img
            style={styles.img}
            src={process.env.PUBLIC_URL + "/images/projects_bg.jpg"}
            onLoad={() => setLoading(false)}
            alt="projects-background"
          />

          <Transition
            visible={!loading}
            animation="fade"
            duration={{ show: 500, hide: 0 }}
          >
            <Dimmer active={true}>
              <div style={styles.dimmerContent}>
                <Header
                  style={{
                    margin: "auto",
                    fontSize: "3rem",
                    color: "rgba(84,255,255,1)",
                  }}
                  as="h1"
                  textAlign="center"
                  icon
                >
                  <Icon
                    style={{
                      color: "rgb(20, 220, 220)",
                    }}
                    name="cogs"
                  />

                  <Header.Content>Projects</Header.Content>
                </Header>
              </div>
            </Dimmer>
          </Transition>
        </Dimmer.Dimmable>
      </div>

      <div
        style={{
          borderTop: "4px solid darkgrey",
          backgroundColor: "#f7f7f7",
        }}
      >
        <Card.Group
          style={{
            display: "flex",
            justifyContent: "start",
            maxWidth: "1600px",
            margin: "auto",
            padding: "30px 30px 0 30px",
          }}
          itemsPerRow={3}
          stackable
          centered
        >
          {projects.map((project, index) => (
            <Card
              style={{
                margin: `0 auto 30px auto`,
                borderRadius: "5px",
              }}
              key={index}
              raised
            >
              <Project
                loadingProjects={loadingProjects}
                index={index}
                img={
                  cards.length === projects.length
                    ? cards.find((el) => el.item === index).url
                    : null
                }
                data={project}
                handler={handleModalOpen}
              />
            </Card>
          ))}
        </Card.Group>
      </div>

      <Details open={open} openHandler={setOpen} data={{ ...modalData }} />
    </>
  );
};

export default Projects;
