import React from "react";

import {
  Button,
  Card,
  Header,
  Icon,
  Modal,
  Grid,
  List,
} from "semantic-ui-react";

const Details = ({ open, openHandler, data }) => {
  return (
    <Modal open={open} dimmer="blurring" size="small">
      <Modal.Header
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          margin: "auto",
        }}
      >
        <Header
          style={{
            width: "100%",
            margin: 0,
            wordWrap: "wrap",
            color: "#147c7c",
          }}
        >
          {data.title}
        </Header>
        <Button
          color="red"
          icon="close"
          size="mini"
          basic
          onClick={() => openHandler(false)}
        />
      </Modal.Header>
      <Modal.Content style={{ backgroundColor: "#f7f7f7" }} scrolling>
        <Grid columns={2} stackable container>
          <Grid.Column>
            <Card
              style={{
                border: "2px solid rgba(0,181,173,.75)",
                borderRadius: "5px",
              }}
              image={data.img}
              raised
              fluid
            />
          </Grid.Column>

          <Grid.Column>
            <Modal.Description>
              <Grid columns={1} stackable container>
                <Grid.Column>
                  <Header>Description</Header>
                  <span>{data.description}</span>
                </Grid.Column>
              </Grid>

              <Grid columns={2} stackable container>
                <Grid.Column>
                  <Header>Technologies</Header>

                  <List>
                    {data.tech.map((el, i) => (
                      <List.Item
                        style={{ display: "flex", alignItems: "baseline" }}
                        icon={
                          <Icon
                            style={{ color: "#147c7c" }}
                            name="dot circle outline"
                            size="small"
                          />
                        }
                        content={el}
                        key={i}
                      />
                    ))}
                  </List>
                </Grid.Column>
                {!!data.features && (
                  <Grid.Column>
                    <Header>Features</Header>

                    <List>
                      {data.features.map((el, i) => (
                        <List.Item
                          style={{ display: "flex", alignItems: "baseline" }}
                          icon={
                            <Icon
                              style={{ color: "#147c7c" }}
                              name="dot circle outline"
                              size="small"
                            />
                          }
                          content={el}
                          key={i}
                        />
                      ))}
                    </List>
                  </Grid.Column>
                )}
              </Grid>
            </Modal.Description>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          icon="github"
          labelPosition="right"
          content="Repository"
          onClick={() => window.open(data.repo, "_blank")}
        />
        <Button
          color="teal"
          icon="desktop"
          labelPosition="right"
          content="View Live"
          onClick={() => window.open(data.live, "_blank")}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default Details;
