import React, { useState } from "react";

import EmailServer from "../smtp.js";

import {
  Dimmer,
  Segment,
  Loader,
  Header,
  Transition,
  Grid,
  Form,
  TextArea,
  Message,
  MessageList,
  Button,
} from "semantic-ui-react";

import Navbar from "../components/Navbar";

const styles = {
  root: {
    height: "100vh",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  img: {
    width: "100%",
    height: "100%",
    maxHeight: "450px",
    objectFit: "cover",
  },

  dimmer: {
    height: "450px",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  dimmerContent: {
    height: "450px",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },

  header: {
    margin: "auto 1rem",
    fontSize: "3rem",
  },

  contentContainer: {
    margin: "auto 0",
    backgroundColor: "#f7f7f7",
    borderTop: "4px solid darkgrey",
  },

  content: {
    maxWidth: window.innerWidth > 500 ? "450px" : window.innerWidth - 50,
    margin: "20px auto",
  },

  title: {
    marginTop: "0.6rem",
    marginBottom: "4px",
  },

  subtitle: {
    marginTop: 0,
  },
};

const Contact = () => {
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    network: "",
  });

  const [sent, setSent] = useState(false);

  const [sending, setSending] = useState(false);

  const handleName = (evt) => {
    let value = evt.target.value;

    setName(value);

    if (!value.trim()) {
      setErrors({ ...errors, name: "Name is required." });
    } else if (value.length < 3) {
      setErrors({ ...errors, name: "Name too short." });
    } else {
      setErrors({ ...errors, name: "" });
    }
  };

  const handleEmail = (evt) => {
    let value = evt.target.value;

    setEmail(value);

    if (!value.trim()) {
      setErrors({ ...errors, email: "Email is required." });
    } else if (!/.+@.+\..+/.test(value)) {
      setErrors({ ...errors, email: "Email should be a valid address." });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handleSubject = (evt) => {
    let value = evt.target.value;

    setSubject(value);

    if (!value.trim()) {
      setErrors({ ...errors, subject: "Subject is required." });
    } else if (value.length < 3) {
      setErrors({ ...errors, subject: "Subject too short." });
    } else {
      setErrors({ ...errors, subject: "" });
    }
  };

  const handleMessage = (evt) => {
    let value = evt.target.value;

    setMessage(value);

    if (!value.trim()) {
      setErrors({ ...errors, message: "Message is required." });
    } else if (value.length < 10) {
      setErrors({ ...errors, message: "Message too short." });
    } else {
      setErrors({ ...errors, message: "" });
    }
  };

  const incomplete = !name || !email || !subject || !message;

  const disabled =
    Object.values(errors).some((el) => !!el) || incomplete || sending || sent;

  const messageError = Object.values(errors).some((el) => !!el);

  const messageHeader = messageError
    ? Object.values(errors).filter((el) => !!el).length > 1
      ? "Errors"
      : "Error"
    : sent
    ? "Your message has been sent."
    : incomplete
    ? "Complete the form before sending"
    : false;

  const messageList = Object.values(errors).map((el) => (!!el ? el : false));

  const sendMessage = () => {
    setSending(true);

    EmailServer.send({
      SecureToken: "d97d11e9-30d2-4b07-a2b0-236e40314684",
      To: "email@jimenezjose.com",
      From: "email@jimenezjose.com",
      Subject: name + " | " + email + " | " + subject,
      Body: message,
    })
      .then((message) => {
        if (message === "OK") {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setSent(true);
          setSending(false);
        } else {
          setErrors({
            ...errors,
            network: "Your message could not be sent, please try again later.",
          });
          setSending(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Navbar />

      <Segment style={{ ...styles.root, display: loading ? "block" : "none" }}>
        <Dimmer active>
          <Loader color="teal" size="massive">
            Loading
          </Loader>
        </Dimmer>
      </Segment>

      <div style={{ ...styles.root, display: loading ? "none" : "block" }}>
        <Dimmer.Dimmable
          style={styles.dimmer}
          as={Segment}
          dimmed={true}
          blurring
        >
          <img
            style={styles.img}
            src={process.env.PUBLIC_URL + "/images/contact_bg.jpg"}
            onLoad={() => setLoading(false)}
            alt="contact-background"
          />

          <Transition
            visible={!loading}
            animation="fade"
            duration={{ show: 500, hide: 0 }}
          >
            <Dimmer active={true}>
              <div style={styles.dimmerContent}>
                <Header style={styles.header} color="teal" as="h1" inverted>
                  Great things start here
                </Header>

                <div></div>
              </div>
            </Dimmer>
          </Transition>
        </Dimmer.Dimmable>

        <Grid style={styles.contentContainer} columns={2} stackable divided>
          <Grid.Column>
            <Grid.Row style={styles.content}>
              <Header as="h2" color="teal">
                Have a question?
              </Header>

              <Header as="h5">
                Send me a message and I will get back to you as soon as
                possible.
              </Header>

              <Form style={{ marginTop: "25px" }}>
                <Form.Input
                  iconPosition="left"
                  placeholder="Name"
                  size="large"
                  icon="user"
                  disabled={sent || sending}
                  error={!!errors.name}
                  value={name}
                  onChange={handleName}
                  fluid
                />

                <br />
                <Form.Input
                  iconPosition="left"
                  placeholder="Email"
                  size="large"
                  icon="at"
                  disabled={sent || sending}
                  error={!!errors.email}
                  value={email}
                  onChange={handleEmail}
                  fluid
                />

                <br />
                <Form.Input
                  iconPosition="left"
                  placeholder="Subject"
                  size="large"
                  icon="sticky note"
                  disabled={sent || sending}
                  error={!!errors.subject}
                  value={subject}
                  onChange={handleSubject}
                  fluid
                />

                <br />
                <TextArea
                  style={{ minHeight: 100, marginBottom: "10px" }}
                  placeholder="Message"
                  disabled={sent || sending}
                  value={message}
                  onChange={handleMessage}
                />
              </Form>

              <Transition
                visible={!!messageHeader}
                transitionOnMount
                animation="fade"
                duration={400}
              >
                <Message
                  success={sent}
                  warning={incomplete && !messageError && !sent}
                  error={messageError}
                >
                  <Message.Header>{messageHeader}</Message.Header>
                  {messageError && (
                    <Transition.Group as={MessageList} duration={400}>
                      {messageList.map((el, index) =>
                        !!el ? (
                          <Message.Item key={index}>{el}</Message.Item>
                        ) : (
                          ""
                        )
                      )}
                    </Transition.Group>
                  )}
                </Message>
              </Transition>

              <Button
                style={{ marginTop: "10px", marginBottom: "10px" }}
                floated="right"
                loading={sending}
                disabled={disabled}
                onClick={() => sendMessage()}
                positive
              >
                Send
              </Button>
            </Grid.Row>
          </Grid.Column>

          <Grid.Column>
            <Grid.Row style={styles.content}>
              <Header as="h2" color="teal">
                Get in touch
              </Header>

              <Header style={styles.title} as="h5">
                Have a suggestion, have a query or just want to say "hello"?
              </Header>

              <Header style={styles.subtitle} as="h5">
                You can reach me at:
              </Header>

              <Header as="h4" color="teal">
                Email
              </Header>
              <Header style={{ marginTop: "1rem" }} as="h5">
                email@jimenezjose.com
              </Header>

              <Header as="h4" color="teal">
                Phone
              </Header>
              <Header style={{ marginTop: "1rem" }} as="h5">
                +57 300-624-2054
              </Header>

              <Header as="h4" color="teal">
                Location
              </Header>
              <Header style={{ marginTop: "1rem" }} as="h5">
                Medellin, Colombia.
              </Header>

              <div style={{ padding: "10px 0" }}>
                <Button
                  style={{ marginRight: "10px" }}
                  color="black"
                  icon="github"
                  basic
                  onClick={() =>
                    window.open("https://github.com/jjdevco", "_blank")
                  }
                />

                <Button
                  style={{ marginRight: "10px" }}
                  color="blue"
                  icon="linkedin"
                  basic
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/in/jose-armando-jimenez-avila-865456b0/",
                      "_blank"
                    )
                  }
                />
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
};

export default Contact;
