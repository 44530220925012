import React from "react";

import { useHistory } from "react-router-dom";

import { Button } from "semantic-ui-react";

const styles = {
  root: {
    height: "80px",
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: window.innerWidth > 720 ? "flex-end" : "center",
    padding: window.innerWidth > 720 ? "0 30px" : 0,
    zIndex: 10000,
  },

  rootDark: {
    background: "black",
  },

  home: {
    marging: "1rem",
  },

  buttons: {
    height: "inherit",
    display: "flex",
    flexDirection: "row",
  },

  button: { height: "40px", margin: "auto 8px", fontWeight: "bold" },
};

const NavButton = ({ path, route, text }) => {
  const history = useHistory();

  return (
    <Button
      basic
      inverted
      active
      style={styles.button}
      color={path === route ? "teal" : null}
      onClick={() => history.push(route)}
    >
      {text}
    </Button>
  );
};

const Navbar = () => {
  const history = useHistory();

  const path = history.location.pathname;

  return (
    <div style={styles.root}>
      <div style={styles.buttons}>
        <NavButton path={path} route="/" text="Home" />
        <NavButton path={path} route="/projects" text="Projects" />
        <NavButton path={path} route="/contact" text="Contact" />
      </div>
    </div>
  );
};

export default Navbar;
