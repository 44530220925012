import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import { Dimmer, Segment, Loader, Header, Transition } from "semantic-ui-react";

import Navbar from "../components/Navbar";

const styles = {
  root: {
    height: "100vh",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  content: {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  header: {
    margin: "0 1rem",
    fontSize: "4rem",
  },

  subheader: {
    display: "inline-block",
    position: "relative",
    margin: "auto 1rem 1rem 1rem",
    fontSize: "3rem",
  },

  cursor: {
    display: window.innerWidth > 500 ? "block" : "none",
    width: "20px",
    borderBottom: "4px solid #54ffff",
    position: "absolute",
    right: "-24px",
    bottom: "10px",
    animation: "cursor 800ms infinite",
  },

  divider: {
    position: "relative",
    borderLeft: "4px solid #54ffff",
    height: "50px",
    left: "50%",
    marginLeft: "-2px",
  },
};

const Home = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  return (
    <>
      <Navbar />

      <Segment style={{ ...styles.root, display: loading ? "block" : "none" }}>
        <Dimmer active>
          <Loader color="teal" size="massive">
            Loading
          </Loader>
        </Dimmer>
      </Segment>

      <Dimmer.Dimmable
        style={{ ...styles.root, display: loading ? "none" : "block" }}
        as={Segment}
        dimmed={true}
        blurring
      >
        <img
          style={styles.img}
          src={process.env.PUBLIC_URL + "/images/home_bg.jpg"}
          onLoad={() => setLoading(false)}
          alt="home-background"
        />
        <Transition visible={!loading} animation="fade" duration={700}>
          <Dimmer active={true}>
            <div style={{ ...styles.root, ...styles.content }}>
              <span></span>
              <div>
                <Header style={styles.header} as="h1" inverted>
                  José Jimenez
                </Header>
                <Header style={styles.subheader} as="div" color="teal" inverted>
                  Full Stack Develover
                  <div style={styles.cursor}></div>
                </Header>
              </div>

              <div>
                <button
                  className="work"
                  to="/projects"
                  onClick={() => history.push("/projects")}
                >
                  View Work
                </button>
                <div style={styles.divider}></div>
              </div>
            </div>
          </Dimmer>
        </Transition>
      </Dimmer.Dimmable>
    </>
  );
};

export default Home;
