import React, { useState } from "react";

import { useHistory } from "react-router-dom";

import {
  Segment,
  Dimmer,
  Loader,
  Transition,
  Button,
  Header,
} from "semantic-ui-react";

const styles = {
  root: {
    height: "100vh",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  dimmer: {
    height: "100vh",
    margin: 0,
    border: 0,
    padding: 0,
    borderRadius: 0,
    boxShadow: "none",
  },

  title: {
    fontSize: "7rem",
    color: "white",
  },

  subtitle: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "50px",
    color: "lightgray",
  },
};

const NotFound = () => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  return (
    <>
      <Segment style={{ ...styles.root, display: loading ? "block" : "none" }}>
        <Dimmer active>
          <Loader color="teal" size="massive">
            Loading
          </Loader>
        </Dimmer>
      </Segment>

      <div style={{ ...styles.root, display: loading ? "none" : "block" }}>
        <Dimmer.Dimmable style={styles.dimmer} as={Segment} dimmed={true}>
          <img
            style={styles.img}
            src={process.env.PUBLIC_URL + "/images/404.jpg"}
            onLoad={() => setLoading(false)}
            alt="404-background"
          />

          <Transition
            visible={!loading}
            animation="fade"
            duration={{ show: 500, hide: 0 }}
          >
            <Dimmer active={true}>
              <Header style={styles.title}>Oops!</Header>
              <Header style={styles.subtitle}>404 - PAGE NOT FOUND</Header>
              <Button color="teal" inverted onClick={() => history.push("/")}>
                Go Home
              </Button>
            </Dimmer>
          </Transition>
        </Dimmer.Dimmable>
      </div>
    </>
  );
};

export default NotFound;
